.home-container {
  height: 100vh;
  width: 100vw;
  background-color: var(--rich-black);
  overflow-y: scroll;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

.home-container > .angle-container {
  transform: translateX(-37%);
}
