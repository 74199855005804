.nav-container {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(50px);
  overflow-y: scroll;
  z-index: 500;
}

.nav-links {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  /* Adjust this for media queries */
  left: 240px;
}

.nav-link {
  list-style: none;
  text-decoration: none;
  color: var(--white);
  font-size: 45px;
  font-weight: 500;
  margin-top: 15px;
}

@media (max-width: 600px) {
  .nav-links {
    left: 50px;
  }

  .nav-link {
    font-size: 35px;
  }
}