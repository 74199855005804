.about-container {
  position: relative;
  /* Adjust width in media queries */
  margin-top: 200px;
  margin-bottom: 200px;
  padding: 0 110px 0 110px; 
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about-description {
  /* Adjust width in media queries */
  width: min(67%, 650px);
  margin-top: 50px;
  white-space: pre-wrap;
  padding: min(10vw, 60px) min(10vw, 80px) min(10vw, 60px) min(10vw, 80px);
  border: var(--white) 1px ridge;
  z-index: 100;
  border-radius: 20px;
  background-color: var(--rich-black);
  border: 2px solid var(--almond);
  filter: drop-shadow(0px 0px 14px var(--almond));
  overflow: hidden
}

.about-description > p {
  font-size: 18px;
  line-height: normal;
}

.about-description > p > span {
  color: var(--almond);
  font-weight: 600;
}

.hobbies-container {
  position: relative;
  width: 96vw;
  /* TODO: scrollable */
  overflow-x: hidden; 
  margin-top: 100px;
  z-index: 100;
}

.hobbies-scrollable {
  display: flex;
  white-space: pre-wrap;
  width: 1500px;
  height: 100%;
  animation: hobbies 30s linear infinite;
}


@keyframes hobbies {
  from {
    transform: translateX(0%);
  }
  to {
    /* arbitrary value that somehow worked... (every card shares 26%) */
    transform: translateX(-183%); 
  }
}

@media (max-width: 800px) {
  .about-container {
    padding: 0 50px 0 50px; 
    margin-top: 100px;
  }
}



@media (max-width: 650px) {
  .about-container {
    padding: 0 5px 0 5px; 
  }

  .about-description > p {
    font-size: 16px;
  }
}

#about-dot3 {
  background-color: var(--black);
  width: 380px;
  height: 380px;
  top: 50%;
  left: -80px;
  filter: drop-shadow(0px 0px 8px var(--black));
  z-index: 0;
}
/* 
#about-dot2 {
  background-color: var(--almond);
  width: 130px;
  height: 130px;
  filter: drop-shadow(0px 0px 8px var(--almond));
  z-index: 0;
  top: -17%;
  left: 60%;

} */

#about-dot {
  background-color: var(--black);
  width: calc(310px + 5vw);
  height: calc(310px + 5vw);
  filter: drop-shadow(0px 0px 12px var(--black));
  z-index: 1;
  top: -12%;
  left: auto;

}

#quote-left {
  position: absolute;
  height: calc(50px + 1.2vw);
  top: calc(8px + 1vw);
  left: calc(1vw);
}

#quote-left2 {
  position: absolute;
  height: calc(170px + 1.2vw);
  top: calc(8px + 1vw - 3vw);
  left: calc(1vw - 3vw);
  opacity: 0.1;
}

#quote-right {
  position: absolute;
  height: calc(50px + 1.2vw);
  bottom: calc(8px + 1vw);
  right: calc(1vw)
}

#quote-right2 {
  position: absolute;
  height: calc(170px + 1.2vw);
  bottom: calc(8px + 1vw - 3vw);
  right: calc(1vw - 3vw);
  opacity: 0.1;
}