/* Remove on smaller screen sizes */
.social-container-vertical {
  flex: 1;
  z-index: 100;
}

.social-icons-vertical {
  display: flex;
  flex-direction: column;
  align-items: end;
  padding-right: 30px;
  gap: 20px;
  cursor: pointer;
}

.social-icon-vertical {
  color: #f2f2f2;
  height: 38px;
}

.social-container-horizontal {
  z-index: 100;
}

.social-icons-horizontal {
  display: flex;
  gap: 36px;
  cursor: pointer;
}

.social-icon-horizontal {
  color: #f2f2f2;
  height: 50px;
}

