.resume-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.resume-container > .resume-description {
  margin-top: 50px;
  font-size: 20px;
  z-index: 100;
}

.resume-preview {
  position: relative;
  margin-top: 50px;
  width: 580px;
  height: 730px;
  object-fit: cover;
  cursor: pointer;
}

.resume-preview > img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.preview-overlay {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  left: 0px;
  top: 0px;
  
}


.preview-overlay:hover {
  background-color: rgb(0, 0, 0, 0.1);;
}


@media (max-width: 800px) {
  .resume-container {
    margin-top: 50px;
  }
}


@media (max-width: 750px) {
  .resume-preview {
    width: 420px;
    height: auto;
  }
}


@media (max-width: 450px) {
  .resume-preview {
    width: 80vw;
  }
}

#resume-dot {
  background-color: var(--black);
  height: calc(300px + 12vw);
  width: calc(300px + 12vw);
  top: -100px;
  right: 3vw;
  filter: drop-shadow(0px 0px 10px var(--black));
}