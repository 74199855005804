.about-card {
  /* Adjust this for phone size */
  min-width: 292px;
  height: 223px;
  background-color: var(--white);
  border-radius: 10px;

  padding: 33px 25px 33px 25px;
  margin-left: 25px;
  margin-right: 25px;

  display: flex;
  flex-direction: column;
  align-items: center;

  z-index: 500;
}


.about-icon {
  width: 70px;
  height: 70px;
}

.about-title {
  margin-top: 30px;
  color: var(--black);
  font-weight: 700;
}

.about-text {
  text-align: center;
  margin-top: 16px;
  font-size: 15px;
  color: var(--black);
  font-weight: 400;
  padding-left: 6px;
  padding-right: 6px;
}