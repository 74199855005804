.nav-button {
  /* Positioning */
  position: absolute;
  top: 50px;
  left: 50px;

  /* Sizing */
  height: 60px;
  width: 60px;
  border-radius: 100%;

  /* Styling */
  border: none;
  background-color: var(--black);
  z-index: 999;

  cursor: pointer;
  z-index: 1000;
}

.nav-button svg {
  position: relative;
  top: 3px;
  left: 1px;
}

@media (max-width: 450px) {
  .nav-button {
    top: 30px;
    left: 20px;
  }
}